// Dependencies
import React, { useState, useEffect, useRef } from "react"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import { useInView } from "react-cool-inview"
// Hooks
// import useToggleCursor from "../hooks/useToggleCursor";
// Components
import Layout from "../components/Layout"
import Img, { ImageLoader } from "../components/Img"
import Image from "../components/Image"
import NewsLink from "../components/NewsLink"
import NewsOverlay from "../components/NewsOverlay"
import RichText from "../components/RichText"
import Vimeo from "../components/Vimeo"

function getAspectRatio(file) {
  const { height, width } = file?.details?.image || {}
  return height && width ? height / width : null
}

const HomepageLink = ({
  title,
  slug,
  description,
  thumbnail,
  thumbnailPortrait,
  homepageCoverLandscape,
  homepageCoverPortrait,
  homepageCoverVimeo,
  type,
}) => {
  const path = type ? `/activity/${type.slug}/` : "/project/"

  // TODO: include homepage cover options
  const landscapeAsset = homepageCoverLandscape || thumbnail

  const landscapeImage =
    landscapeAsset?.landscape ?? landscapeAsset?.gatsbyImageData

  const landscapeGif =
    landscapeAsset?.file?.contentType === "image/gif"
      ? landscapeAsset?.file?.url
      : null

  const landscapeAspect = homepageCoverVimeo
    ? "0.5625"
    : String(getAspectRatio(landscapeAsset?.file) ?? 0.636)

  const portraitAsset =
    homepageCoverPortrait || thumbnailPortrait || landscapeAsset

  const portraitImage =
    portraitAsset?.portrait ?? portraitAsset?.gatsbyImageData

  const portraitGif =
    homepageCoverPortrait?.file?.contentType === "image/gif"
      ? homepageCoverPortrait?.file?.url
      : null

  const portraitAspect = homepageCoverVimeo
    ? "0.5625"
    : String(getAspectRatio(portraitAsset?.file) ?? 1.571)

  // UX
  const { observe, inView } = useInView({
    // Stop observe when the target enters the viewport, so the "inView" only triggered once
    unobserveOnEnter: true,
    // For better UX, we can grow the root margin so the image will be loaded before it comes to the viewport
    rootMargin: "50px",
  })

  return (
    <div className="homepage-link" ref={observe}>
      {/* mobile/portrait */}
      <div
        className="prel thumbnail--portrait show-portrait bg-light-grey"
        style={{
          aspectRatio: `1 / ${portraitAspect}`,
        }}
      >
        <Link
          to={`${path}${slug}`}
          className="prel fs block ignore-gatsby-padding"
        >
          {homepageCoverVimeo && inView ? (
            <Vimeo url={homepageCoverVimeo} />
          ) : portraitImage && inView ? (
            <Image gif={portraitGif} image={portraitImage} />
          ) : null}
        </Link>
      </div>

      {/* Landscape/desktop */}
      <div
        className="prel thumbnail--landscape show-landscape bg-light-grey"
        style={{
          aspectRatio: `1 / ${landscapeAspect}`,
        }}
      >
        <Link
          to={`${path}${slug}`}
          className="prel fs block ignore-gatsby-padding"
        >
          {homepageCoverVimeo && inView ? (
            <Vimeo url={homepageCoverVimeo} />
          ) : landscapeImage && inView ? (
            <Image gif={landscapeGif} image={landscapeImage} />
          ) : null}
        </Link>
      </div>

      <div className="p3 guttersx2 outer h3-nudge no-m">
        <h3>{title}</h3>
        {description && <RichText content={description} />}
      </div>
    </div>
  )
}

function sortHomepageContent(projects, news) {
  const shuffledProjects = projects.sort(() => Math.random() - 0.5)
  const array = []
  let count = 0
  shuffledProjects.forEach((item, index) => {
    array.push(item)
    if (index % 3 === 2) {
      if (news[count]) {
        array.push(news[count])
        count = count + 1
      }
    }
  })
  return array
}

const IndexPage = ({ data, pageContext }) => {
  const { projects, featuredProjects } = data.contentfulHomepage
  const news = data.allContentfulNews.nodes

  // const combined = useRef(
  // 	sortHomepageContent(projects, news)
  // );
  // const [combined] = useState(sortHomepageContent(projects, news))
  const [links, setLinks] = useState(projects)
  useEffect(() => {
    setLinks(sortHomepageContent(projects, news))
  }, [])

  // UX
  const [activeNews, setNews] = useState(null)

  // UI
  const handleClose = () => {
    setNews(null)
  }

  return (
    <Layout hasManifesto={true}>
      <section className="homepage">
        {featuredProjects?.length > 0 &&
          featuredProjects.map((project, index) => (
            <HomepageLink {...project} key={`featured-${index}`} />
          ))}

        {links?.map((entry, index) =>
          entry.headline ? (
            <NewsLink
              entry={entry}
              overlayOpen={activeNews}
              openNewsOverlay={setNews}
              key={`homepage-${index}`}
            />
          ) : (
            <HomepageLink {...entry} key={`homepage-${index}`} />
          )
        )}
      </section>

      <NewsOverlay entry={activeNews} handleClose={handleClose} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const IndexPagePageQuery = graphql`
  fragment ContentfulAssetFile on ContentfulAsset {
    file {
      details {
        image {
          height
          width
        }
      }
      contentType
      url
    }
  }

  fragment ProjectCard on ContentfulProject {
    __typename
    title
    slug
    description {
      raw
      references {
        ... on ContentfulCompanyValue {
          contentful_id
          __typename
          colour
        }
      }
    }
    thumbnail {
      ...ContentfulAssetFile
      portrait: gatsbyImageData(
        layout: FULL_WIDTH
        height: 1800
        quality: 90
        formats: JPG
      )
      landscape: gatsbyImageData(
        layout: FULL_WIDTH
        width: 1920
        quality: 90
        formats: JPG
      )
    }
    thumbnailPortrait {
      ...ContentfulAssetFile
      gatsbyImageData(
        layout: FULL_WIDTH
        height: 1800
        quality: 90
        formats: JPG
      )
    }
    homepageCoverLandscape {
      ...ContentfulAssetFile
      portrait: gatsbyImageData(
        layout: FULL_WIDTH
        height: 1800
        quality: 90
        formats: JPG
      )
      landscape: gatsbyImageData(
        layout: FULL_WIDTH
        width: 1920
        quality: 90
        formats: JPG
      )
    }
    homepageCoverPortrait {
      ...ContentfulAssetFile
      gatsbyImageData(
        layout: FULL_WIDTH
        height: 1800
        quality: 90
        formats: JPG
      )
    }
    homepageCoverVimeo
  }

  # (placeholder) 4DTA7iKPEMj9cRgZGvQJxs
  # (live) 1CvtZHV6BubKR9pi2scyyL
  query IndexPageQuery {
    contentfulHomepage(contentful_id: { eq: "1CvtZHV6BubKR9pi2scyyL" }) {
      featuredProjects {
        ... on Node {
          ... on ContentfulProject {
            ...ProjectCard
          }
        }
      }
      projects {
        ... on Node {
          ... on ContentfulProject {
            ...ProjectCard
          }
          ... on ContentfulActivity {
            __typename
            title
            slug
            type {
              slug
            }
            description {
              raw
              references {
                ... on ContentfulCompanyValue {
                  contentful_id
                  __typename
                  colour
                }
              }
            }
            thumbnail {
              fluid(maxWidth: 1800, quality: 90) {
                ...GatsbyContentfulFluid_noBase64
              }
              portrait: gatsbyImageData(
                layout: CONSTRAINED
                width: 1170
                height: 1800
                quality: 80
                formats: JPG
                resizingBehavior: FILL
              )
              landscape: gatsbyImageData(
                layout: FULL_WIDTH
                quality: 80
                formats: JPG
              )
            }
            thumbnailPortrait {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 1170
                height: 1800
                quality: 80
                formats: JPG
                resizingBehavior: FILL
              )
            }
          }
        }
      }
    }
    allContentfulNews(sort: { order: DESC, fields: date }) {
      nodes {
        ...ContentfulNewsFragment
      }
    }
  }
`

// export const HomepageCoverImageFragment = graphql`
// 	fragment HomepageCoverImageFragment on ContentfulAsset {

// 	}
// `
