import React from "react";
// Components
import { CommentIcon, RetweetIcon, LikeIcon } from "../components/Icons";

const TweetButtons = React.memo(({ tweetUrl}) => {

	const handle = tweetUrl.split('/status/')[1];
	const replyLink = `https://twitter.com/intent/tweet?in_reply_to=${handle}`;
	const retweetLink = `https://twitter.com/intent/retweet?tweet_id=${handle}`;
	const likeLink = `https://twitter.com/intent/like?tweet_id=${handle}`;

	return (
		<div className="tweet-buttons">
			<a href={replyLink} target="_blank" rel="noreferrer"><CommentIcon /></a>
			<a href={retweetLink} target="_blank" rel="noreferrer"><RetweetIcon /></a>
			<a href={likeLink} target="_blank" rel="noreferrer"><LikeIcon /></a>
		</div>
	)
})

export default TweetButtons