// Dependencies
import React, { useRef, useState, useEffect } from "react"
import Player from "@vimeo/player"

export default function useVimeo(
  config = {
    url: "https://vimeo.com/321249877",
    byline: false,
    color: "#FFF",
    portrait: false,
    title: false,
    background: 1,
  }
) {
  // UX
  const ref = useRef()
  const [player, setPlayer] = useState(null)
  const [ready, setReady] = useState(false)
  const [aspectRatio, setAspectRatio] = useState(0.56)

  useEffect(() => {
    setPlayer(new Player(ref.current, config))
  }, [])

  useEffect(() => {
    let isInitialized
    if (player) {
      isInitialized = true
      Promise.all([player.getVideoWidth(), player.getVideoHeight()]).then(
        dimensions => {
          if (isInitialized && player) {
            setAspectRatio(dimensions[1] / dimensions[0])
            setReady(true)
          }
        }
      )
    }
    return () => (isInitialized = false)
  }, [player])

  // UI
  // ...

  const unmute = () => {
    if (player) {
      player.setMuted(false).then(() => {
        console.log("unmuted")
      })
    }
  }

  const unmuteAndRestart = () => {
    if (player) {
      player.setCurrentTime(0).then(() => {
        console.log("restarted")
        player.setMuted(false).then(() => {
          console.log("unmuted")
          player.play().then(() => {
            console.log("played")
          })
        })
      })
    }
  }

  return { ref, ready, aspectRatio, unmute, unmuteAndRestart }
}
