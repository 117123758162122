import React, { useRef, useState, useEffect, useCallback } from "react"
// import Player from "@vimeo/player"
import useVimeo from "../hooks/useVimeo"

export default function Vimeo({ url, inView }) {
  const [willPlay, setWillPlay] = useState(false)

  const isTouch =
    typeof window !== `undefined` && window.matchMedia("(hover: none)").matches

  const { ref, ready, aspectRatio, unmute, unmuteAndRestart } = useVimeo({
    url,
    autoplay: !isTouch,
    loop: !isTouch,
    muted: !isTouch,
  })

  const handlePlay = e => {
    e.preventDefault()
    unmuteAndRestart()
    setWillPlay(true)
  }

  return (
    <div className="vimeo-container fs pabs" ref={ref}>
      {!willPlay && !isTouch && (
        <button className="vimeo-overlay fs pabs z-2" onClick={handlePlay} />
      )}
    </div>
  )
}
