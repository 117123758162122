// Dependencies
import React, { useState } from "react"
// Hooks
// ...
// Components
import Img from "../components/Img";
import RichText from "../components/RichText";
import Lightbox from "../components/Lightbox";

const NewsOverlay = ({
	handleClose,
	entry
}) => {

	return (
		entry && (
			<Lightbox handleClose={handleClose}>
				<div className="column stick">
					{entry.image && <Img {...entry.image} />}
				</div>
				<div className="column">
					<h5 className="mb">{entry.date}</h5>
					<div>
						<h1>{entry.headline}</h1>
						<div className="p3 outer has-visible-links">
							<RichText content={entry.body} />
						</div>
					</div>
				</div>
			</Lightbox>
		)
	)
}

export default NewsOverlay