// Dependencies
import React, { useContext, useEffect, useState, useRef } from "react";
import { useTransition, animated } from "react-spring";
// Hooks
import useLockBodyScroll from "../hooks/useLockBodyScroll";
// Components
import { AppActionsContext } from "../components/App";
import { Cross } from "../components/Icons";

const Lightbox = ({ handleClose, children }) => {

	// Refs
	const scrollingEl = useRef()

	// UX
	// useLockBodyScroll();
	const [show, setShow] = useState(false)
	const [setShowCursor, setCursorToggleState] = useContext(AppActionsContext);
	const transitions = useTransition(show, null, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
		onDestroyed: (item) => {
			if (item) {
				handleClose()
			}
		}
	})

	// Prevent body from scrolling when open...
	useLockBodyScroll(show, scrollingEl)

	useEffect(() => {
		// On mount..
		setShowCursor(true)
		setCursorToggleState(true)
		setShow(true)
		return () => {
			// Dismount...
			setShowCursor(false)
			setCursorToggleState(false)
		}
	}, [])

	// UI
	const handlers = {
		onMouseEnter: (event) => {
			event.preventDefault()
			setShowCursor(true)
		},
		onClick: () => {
			setShow(false)
			setCursorToggleState(false)
		}
	}

	return (
		transitions.map(({ item, key, props }) =>
			item && (
				<animated.div className="lightbox" {...handlers} key={key} style={props}>
					<button className="lightbox__close"><Cross /></button>
					<div className="lightbox__content" ref={scrollingEl}>
						{children}
					</div>
				</animated.div>
			)
		)
	)
}

export default Lightbox