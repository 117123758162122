// Dependencies
import React, { useContext } from "react";
import { graphql } from "gatsby";
// Components
import { AppActionsContext } from "../components/App";
import LinkType from "../components/LinkType";
import TweetButtons from "../components/TweetButtons";
// import ToggleCursor, { useCursorState } from "../components/ToggleCursor"

const NewsLink = ({ className = "guttersx2 outerx4", entry, overlayOpen, openNewsOverlay }) => {

	// Content
	const { headline, caption, date, externalUrl, tweetUrl, linkLabel, body } = entry;

	// UI
	// const [showCursor, cursorHandlers, style] = useCursorState();
	const [setShowCursor] = useContext(AppActionsContext);

	const handlers = {
		onClick: () => {
			if (openNewsOverlay && body) {
				openNewsOverlay(entry)
			}
		},
		onMouseEnter: () => !body || setShowCursor(true),
		onMouseOver: () => !body || setShowCursor(true),
		onMouseLeave: () => !body || setShowCursor(false)
	}

	return (
		<div className={`news-item ${className}`} {...handlers}>
			<h5 className="">
				<span>{date}</span> 
				{(externalUrl || tweetUrl) && (
					<span>&nbsp; {`|`} &nbsp;</span>
				)}
				{externalUrl && (
					<LinkType className="link" url={externalUrl}>{linkLabel || 'Link'}</LinkType> 
				)}
				{tweetUrl && (
					<TweetButtons tweetUrl={tweetUrl} />
				)}
			</h5>
			<h1 className="">
				<LinkType url={externalUrl || tweetUrl}>{headline}</LinkType>
			</h1>
			{caption && (
				<div className="p3">
					<p>{caption.caption}</p>
				</div>
			)}
			{body && (
				<div className="outer read-more">Read more +</div>
			)}
		</div>
	)
}

export default NewsLink

export const ContentfulNewsFragment = graphql`
  fragment ContentfulNewsFragment on ContentfulNews {
    id
		headline
		caption {
			caption
		}
		body {
			raw
		}
		image {
			fluid(maxWidth: 1080, quality: 90) {
				...GatsbyContentfulFluid_noBase64
			}
		}
		tweetUrl
		externalUrl
		linkLabel
		fromNow: date(fromNow: true, formatString: "")
		date(formatString: "DD.MM.YYYY")
  }
`